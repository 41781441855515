import Bowser from 'bowser';
import './index.less';

type ESVersion = 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
class DouyinCreatorBrowerCheck {
  private cssPrefix = 'douyin-creator-browser-check';
  private idPrefix = 'douyin-creator-browser-check';
  private locationStorageKey = 'douyin-creator-browser-check__has_shown';
  private esVersion: ESVersion = 11;

  private recommendedBrowsers: {
    imageUrl: string;
    title: string;
    jumpUrl: string;
  }[] = [
    {
      imageUrl: 'https://lf3-static.bytednsdoc.com/obj/eden-cn/lm-yvahlyj-upfbvk/ljhwZthlaukjlkulzlp/pc/icons/edge.png',
      jumpUrl: 'https://www.microsoft.com/zh-cn/edge/download',
      title: 'Edge 浏览器',
    },
    {
      imageUrl:
        'https://lf3-static.bytednsdoc.com/obj/eden-cn/lm-yvahlyj-upfbvk/ljhwZthlaukjlkulzlp/pc/icons/chrome.png',
      title: 'Chrome 浏览器',
      jumpUrl: 'https://www.google.cn/chrome/',
    },
  ];

  public isValidBrowser = false;

  private btnCloseId = `${this.idPrefix}-btn-close`;
  private btnClosePermanentId = `${this.idPrefix}-btn-close-permanent`;

  constructor({
    checkTree = {
      safari: '>=14',
      chrome: '>=79',
      edge: '>=113',
      firefox: '>=113',
      qq: '>=11.7',
      brave: '>=8',
    },
    cssPrefix,
    idPrefix,
    className = '',
    recommendedBrowsers,
    esVersion
  }: {
    checkTree?: Bowser.Parser.checkTree;
    cssPrefix?: string;
    idPrefix?: string;
    className?: string;
    recommendedBrowsers?: {
      imageUrl: string;
      title: string;
      jumpUrl: string;
    }[];
    esVersion?: ESVersion;
  }) {
    if (idPrefix) {
      this.idPrefix = idPrefix;
      this.btnCloseId = `${this.idPrefix}-btn-close`;
      this.btnClosePermanentId = `${this.idPrefix}-btn-close-permanent`;
    }
    if (cssPrefix) {
      this.cssPrefix = cssPrefix;
    }
    if (recommendedBrowsers) {
      this.recommendedBrowsers = recommendedBrowsers;
    }
    if(esVersion) {
      this.esVersion = esVersion;
    }

    const browser = Bowser.getParser(window.navigator.userAgent);
    console.log('[DouyinCreatorBrowerCheck]  browser version', browser.getBrowser());
    this.isValidBrowser = browser.satisfies(checkTree) || false;
    console.log('[DouyinCreatorBrowerCheck]  isValidBrowser', this.isValidBrowser);

    // init wrapperNode
    this.wrapperNode = document.createElement('div');
    this.wrapperNode.setAttribute('class', `${this.cssPrefix} ${className}`);
    document.body.appendChild(this.wrapperNode);

    // init contentNode
    this.contentNode = this.createNode(
      `<div id="" class="${this.cssPrefix}-content"></div>`,
    );
    this.modalNode = this.createNode(`<div class="${this.cssPrefix}-modal">
      <div class="${
        this.cssPrefix
      }-modal-text">你的浏览器可能不太兼容当前平台，可能会导致平台无法正常使用，建议尽快升级</div>
      <div class="${this.cssPrefix}-browser">
        ${this.recommendedBrowsers
          .map(
            browser => `<a class="${this.cssPrefix}-browser-item" href="${browser.jumpUrl}">
            <img class="${this.cssPrefix}-browser-img" src="${browser.imageUrl}"></img>
            <div class="${this.cssPrefix}-browser-title">${browser.title}</div>
          </a>`,
          )
          .join('')}
      </div>
      <div class="${this.cssPrefix}-modal-btns">
        <button class="${this.cssPrefix}-modal-btn" id="${this.btnClosePermanentId}">不再提醒</button>
        <button class="${this.cssPrefix}-modal-btn-primary" id="${this.btnCloseId}">我知道了</button>
      </div>
    </div>`);
    this.contentNode.appendChild(this.modalNode);
    this.contentNode.addEventListener('click', this.handleClickMask);
  }

  private wrapperNode: HTMLDivElement;
  private contentNode: ChildNode;
  private modalNode: ChildNode;

  private handleClickMask = (e: Event) => {
    if (this.modalNode.contains(e.target as Node)) {
      return;
    }
    this.hideContent();
  };

  private handleClickNeverShow = () => {
    this.hideContent();
    window.localStorage.setItem(this.locationStorageKey, 'true');
  };

  private hideContent = () => {
    this.wrapperNode.removeChild(this.contentNode);
  };

  private showContent = () => {
    this.wrapperNode.appendChild(this.contentNode);

    const btnClose = document.getElementById(this.btnCloseId);
    btnClose?.addEventListener('click', this.hideContent);

    const btnClosePermanent = document.getElementById(this.btnClosePermanentId);
    btnClosePermanent?.addEventListener('click', this.handleClickNeverShow);
  };
  private createNode(htmlStr: string) {
    const div = document.createElement('div');
    div.innerHTML = htmlStr;
    return div.childNodes[0];
  }

  /** https://stackoverflow.com/questions/47374678/how-to-detect-ecmascript-version */
  checkESVersion = (): ESVersion => {
    const array: any[] = [];
    switch (true as boolean) {
      case !Array.isArray:
        return 3;
      case !window.Promise:
        return 5;
      case !array.includes:
        return 6;
      case !''.padStart:
        return 7;
      case !Promise.prototype.finally:
        return 8;
      case !window.BigInt:
        return 9;
      case !Promise.allSettled:
        return 10;
      case !''.replaceAll:
        return 11;
      case !array.at:
        return 12;
      default:
        return 13;
    }
  };

  public check = () => {
    const isManuallyHide = Boolean(window.localStorage?.getItem?.(this.locationStorageKey));
    if (isManuallyHide) {
      console.log('[DouyinCreatorBrowserCheck] 用户选择跳过浏览器兼容性检测');
      return;
    }

    console.log('[DouyinCreatorBrowserCheck] checkESVersion', this.checkESVersion());
    if(!(this.checkESVersion() >= this.esVersion) || !this.isValidBrowser) {
      this.showContent();
    }
  };
}

export default DouyinCreatorBrowerCheck;
